import debounce from 'lodash/debounce';
import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    tagName: {
      type: String,
      default: 'nav',
    },
    modLastItemIsActive: {
      type: Boolean,
      default: false,
    },
    label: String,
    modBar: Boolean,
    modBold: Boolean,
    modCondensed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCondensed: this.modCondensed,
      resizeListener: null,
    };
  },
  mounted() {
    this.initChildItems();
    this.updateChildItems();
    this.resizeListener = debounce(() => { this.updateChildItems(); }, 50);
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
  updated() {
    this.initChildItems();
    this.updateChildItems();
  },
  computed: {
    classes() {
      return [
        'vl-breadcrumb',
        {
          'vl-breadcrumb--bold': this.modBold,
          'vl-breadcrumb--condensed': this.isCondensed,
        },
      ];
    },
    items() {
      return this.$children.filter((item) => {
        if (item.classes.length) {
          return item.classes.includes('vl-breadcrumb__list__item');
        }
        return false;
      });
    },
    barClasses() {
      return [
        'vl-breadcrumb-container',
        {
          'vl-breadcrumb-bar': this.modBar,
        },
      ];
    },
    labelText() {
      return this.label ? this.label : this.$i18n.t('breadcrumb.label');
    },
  },
  methods: {
    initChildItems() {
      this.items.forEach((item) => {
        item.isCondensed = false;
        item.useSeparator = true;
        item.isLastItem = false;
      });

      if (this.items.length === 1) {
        this.items[0].useSeparator = false;
      }

      if (this.items.length > 2) {
        this.items[2].$on('removeCondensed', () => {
          this.isCondensed = false;
        });
      }
    },
    updateChildItems() {
      this.$nextTick(() => {
        if (this.isCondensed) {
          const containerWidth = this.$refs.breadcrumbContainer.clientWidth;
          const breadcrumbsWidth = this.$refs.breadcrumbList.clientWidth;
          /*
          * Breadcrumbs longer then container
          */
          if (breadcrumbsWidth > containerWidth && this.items.length > 2) {
            let reduced = false;
            this.items.forEach((item, index) => {
              // Only condense from third item until the second last item
              if (index >= 2 && index < this.items.length - 1 && !item.isCondensed && !reduced) {
                item.isCondensed = true;
                reduced = true;
                // Execute a second time to make sure the all breadcrumbs now fit inside the container
                this.updateChildItems();
              }
            });
            // Still too large and no items can be condensed? Condensing second item is last resort (which should be visible as long as possible).
            if (!reduced) {
              this.items[1].isCondensed = true;
            }
          /*
          * Breadcrumbs shorter then container with room for at least one extra breadcrumb (max width 250px)
          */
          } else if (breadcrumbsWidth + 250 <= containerWidth) {
            let enlarged = false;
            this.items.forEach((item) => {
              if (!enlarged && item.isCondensed && !item.isThirdItem) {
                item.isCondensed = false;
                enlarged = true;
                // Execute a second time to make sure more breadcrumbs can fit inside the container now
                this.updateChildItems();
              }
            });
            // Make the last one also visible if no more breadcrumbs can be revealed anymore
            if (!enlarged && this.items.length > 2) {
              this.items[2].isCondensed = false;
              this.items[2].isThirdItem = true;
            }
          }
          // Always show ellipsis (...) on third item
          if (this.items.length > 2) {
            if (this.items.find(item => item.isCondensed)) {
              this.items[2].isCondensed = true;
              this.items[2].isThirdItem = true;
            }
          }
        }

        // Disable last breadcrumb if configured so
        if (this.items && this.items.length) {
          this.items[this.items.length - 1].isLastItem = true;
          this.items[this.items.length - 1].lastItemActive = this.modLastItemIsActive;
        }
      });
    },
  },
};
