import { VlIcon } from '@govflanders/vl-ui-icon/src/vue';

import nlBE from './locale/nl-BE';
import frBE from './locale/fr-BE';
import enGB from './locale/en-GB';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
      'fr-BE': frBE,
      'en-GB': enGB,
    },
  },
  components: {
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    icon: String,
    title: {
      type: String,
    },
    titleTagName: {
      type: String,
      default: 'span',
    },
    image: String,
    closeText: String,
    content: String,
    closable: Boolean,
    modSmall: Boolean,
    modSuccess: Boolean,
    modWarning: Boolean,
    modError: Boolean,
    modCta: Boolean,
    modLarge: Boolean,
    modNaked: Boolean,
    modInfo: Boolean,
    modCenter: Boolean,
    /**
     * Add role="alert" for status messages that appear after page load and don't contain interactive content.
     * Add role="alertdialog" for status messages that appear after page load that contain interactive content and
     * implement a focus jump to the content of the dialog.
     */
    role: {
      type: String,
      default: null,
      validator: (value) => ['alert', 'alertdialog'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      modFadeOut: false,
      visible: true,
    };
  },
  computed: {
    classes() {
      return [
        'vl-alert',
        {
          'vl-alert--small': this.modSmall,
          'vl-alert--success': this.modSuccess,
          'vl-alert--warning': this.modWarning,
          'vl-alert--error': this.modError,
          'vl-alert--cta': this.modCta,
          'vl-alert--fade-out': this.modFadeOut,
          'vl-alert--large': this.modLarge,
          'vl-alert--naked': this.modNaked,
          'vl-alert--info': this.modInfo,
          'vl-alert--center': this.modCenter,
        },
      ];
    },
    titleContentClasses() {
      return [
        'vl-alert__title',
        {
          'vl-u-mark--accent': this.modNaked,
          'vl-u-mark--success': this.modSuccess && this.modNaked,
          'vl-u-mark--warning': this.modWarning && this.modNaked,
          'vl-u-mark--error': this.modError && this.modNaked,
          'vl-u-mark--info': this.modInfo && this.modNaked,
        },
      ];
    },
    messageClasses() {
      return [
        'vl-alert__message',
      ];
    },
    nakedClasses() {
      return {
        'vl-u-mark--success': this.modSuccess && this.modNaked,
        'vl-u-mark--warning': this.modWarning && this.modNaked,
        'vl-u-mark--error': this.modError && this.modNaked,
        'vl-u-mark--info': this.modInfo && this.modNaked,
      };
    },
    slotAction() {
      return this.$slots.actions && this.$slots.actions.length;
    },
    messageOrDefaultSlot() {
      return this.content || this.$slots.default;
    },
    hasTitle() {
      return this.title || this.$slots.title;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
