/* eslint no-underscore-dangle: [2, { 'allow': ['_componentTag',  '_isVue'] }] */
import Vue from 'vue';
import VlIcon from '@govflanders/vl-ui-icon/src/vue';
import VlTypography from '@govflanders/vl-ui-typography/src/vue';
import VlAnnotation from '@govflanders/vl-ui-annotation/src/vue';
import VlLink from '@govflanders/vl-ui-link/src/vue';
import VlBadge from '@govflanders/vl-ui-badge/src/vue';
import nlBE from './locale/nl-BE';
import frBE from './locale/fr-BE';
import enGB from './locale/en-GB';

export default {
  inheritAttrs: false,
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
      'fr-BE': frBE,
      'en-GB': enGB,
    },
  },
  data() {
    return {
      iconSize: '6rem',
      initialsSize: '4.3rem',
      badgeProps: {
        modXxLarge: true,
      },
      resizeListener: null,
      currentBreakpoint: null,
    };
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    titleTagName: {
      type: String,
      default: 'h3',
    },
    titleAnnotation: [String, Number],
    href: String,
    titleIcon: String,
    to: null,
    target: {
      type: String,
      default: '_self',
    },
    title: {
      type: String,
    },
    subtitle: String,
    text: String,
    modHorizontal: Boolean,
    image: {
      src: String,
      srcset: String,
      alt: String,
      position: {
        type: String,
        default: 'center-center',
      },
      modRounded: Boolean,
      modContained: Boolean,
    },
    badgeInitials: String,
    data: {
      value: String,
      subvalues: Array,
    },
    modExternal: Boolean,
    modXsmall: Boolean,
    modSmall: Boolean,
    modLarge: Boolean,
    modDocument: Boolean,
    modNoBorder: {
      type: Boolean,
      default: false,
    },
    modNoVisualBorder: Boolean,
    modAlt: Boolean,
    modColorBlockAlt: Boolean,
    modColorBlockDark: Boolean,
    modInline: Boolean,
    modFocusAlt: Boolean,
    colorBlock: Boolean,
    transparentBlock: Boolean,
    icon: String,
    linkIcon: String,
  },
  components: {
    VlIcon,
    VlTypography,
    VlAnnotation,
    VlLink,
    VlBadge,
  },
  computed: {
    classes() {
      return [
        'vl-spotlight', {
          'vl-spotlight--xs': this.modXsmall,
          'vl-spotlight--s': this.modSmall,
          'vl-spotlight--l': this.modLarge,
          'vl-spotlight--alt': this.modAlt,
          'vl-spotlight--no-border': this.modNoBorder,
          'vl-spotlight--no-visual-border': this.modNoVisualBorder,
          'vl-spotlight--inline': this.modInline,
          'vl-spotlight--image': !!this.image,
          'vl-spotlight--horizontal': this.modHorizontal,
          'vl-spotlight--alt-focus': this.modFocusAlt,
          'vl-spotlight--link': this.isALink,
        },
      ];
    },
    headerClasses() {
      const headerClasses = [];

      if (this.image) {
        headerClasses.push('vl-spotlight__image');
        if ('position' in this.image) {
          headerClasses.push(`vl-spotlight__image--focus-${this.image.position}`);
        }
        if ('ratio' in this.image) {
          headerClasses.push(`vl-spotlight__image--${this.image.ratio}`);
        }
        if (this.image?.modContained) {
          headerClasses.push('vl-spotlight__image--contained');
        }
      }
      if (!this.image && this.badgeInitials) {
        headerClasses.push('vl-spotlight__image');
      }
      if (this.colorBlock) {
        headerClasses.push('vl-spotlight__color-block');
      }
      if (this.modColorBlockAlt) {
        headerClasses.push('vl-spotlight__color-block--alt');
      }
      if (this.modColorBlockDark) {
        headerClasses.push('vl-spotlight__color-block--dark');
      }
      if (this.transparentBlock) {
        headerClasses.push('vl-spotlight__transparent-block');
      }
      if (this.transparentBlock && this.image) {
        headerClasses.push('vl-spotlight__transparent-block--visual');
      }
      return headerClasses;
    },
    headerImageClasses() {
      return ['vl-spotlight__image__img'];
    },
    headerImage() {
      return this.image;
    },
    showDefaultSlot() {
      return !this.text && !this.modDocument && this.$slots.default;
    },
    hasAnnotationSlot() {
      return !!this.$slots.annotation;
    },
    hasTitleSlot() {
      return !!this.$slots.title;
    },
    isALink() {
      return !!this.to;
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
      );
    },
  },
  mounted() {
    this.breakpointChanged(Vue.directive('vl-breakpoint').getBreakpoint());
  },
  methods: {
    breakpointChanged(bp) {
      this.currentBreakpoint = bp;

      if (this.$refs.header) {
        if (this.$refs.header.clientWidth < 100) {
          this.iconSize = '3rem';
          this.initialsSize = '2rem';
        } else if (this.$refs.header.clientWidth < 250) {
          this.iconSize = '4.5rem';
          this.initialsSize = '3.2rem';
        } else if (this.$refs.header.clientWidth < 350) {
          this.iconSize = '6rem';
          this.initialsSize = '4.3rem';
        } else {
          this.iconSize = '9rem';
          this.initialsSize = '7rem';
        }
      }
    },
  },
};
