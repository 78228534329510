import nlBE from './locale/nl-BE';

export default {
  i18n: {
    locale: 'nl-BE',
    messages: {
      'nl-BE': nlBE,
    },
  },
  props: {
    modError: Boolean,
    modDisabled: Boolean,
    modSmall: Boolean,
    modInline: Boolean,
    modBlock: Boolean,
    value: null,
    type: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    classes() {
      return [
        'vl-input-field',
        { 'vl-input-field--error': this.modError },
        { 'vl-input-field--disabled': this.modDisabled },
        { 'vl-input-field--small': this.modSmall },
        { 'vl-input-field--inline': this.modInline },
        { 'vl-input-field--block': this.modBlock },
      ];
    },
    inputListeners() {
      const vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input: (event) => {
            vm.$emit('input', event.target.value);
          },
        },
      );
    },
  },
};
